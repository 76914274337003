<template>
  <v-row justify="center">
    <v-dialog
      v-model="value"
      content-class="elevation-0"
      :max-width="maxWidth"
      persistent
    >
      <v-container>
        <v-card height="auto" style="border-radius: 15px" color="white">
          <v-card-title v-if="showClose" class="text-h5 primary--text">
            <v-row class="justify-end">
              <v-col cols="2" class="d-flex justify-end">
                <v-icon color="gray" @click="closeDialog"> mdi-close </v-icon>
              </v-col>
            </v-row>
          </v-card-title>

          <!-- Sección de título con icono -->
          <v-card-title class="text-h5 primary--text text-center">
            <v-container>
              <v-row justify="center">
                <span
                  class="primary--text font-weight-bold text-center"
                  style="color: #1b1d4b !important; word-break: auto-phrase"
                >
                  {{ title }}
                </span>
              </v-row>
              <v-row justify="center" class="mt-4 mb-4">
                <v-icon v-if="icon" :color="iconColor" size="36">
                  {{ icon }}
                </v-icon>
              </v-row>
            </v-container>
          </v-card-title>

          <!-- Sección de descripción opcional -->
          <v-card-subtitle v-if="descripcion" class="text-center px-6">
            {{ descripcion }}
          </v-card-subtitle>

          <section class="container px-8">
            <slot name="content" />
          </section>

          <div class="actions-buttons" :class="classButtons">
            <slot name="actions" />
          </div>
        </v-card>
      </v-container>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    title: String,
    descripcion: String, // Nueva prop para la descripción
    icon: String, // Nueva prop para el ícono
    iconColor: {
      // Color del ícono
      type: String,
      default: "red",
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    value: Boolean,
    maxWidth: {
      type: String,
      default: "600px",
    },
    classButtons: {
      type: String,
      default: "d-flex justify-end",
    },
  },
  watch: {
    value(newVal) {
      if (!newVal) {
        this.$emit("closed");
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit("input", false);
    },
  },
};
</script>

<style scoped>
.actions-buttons button {
  margin-right: auto;
  margin-left: auto;
}
</style>
