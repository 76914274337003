<template>
  <v-app-bar app :elevation="0" color="bgWhite" prominent>
    <v-app-bar-nav-icon @click.stop="setSideBar(!sideBar)"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>

    <!-- <v-menu offset-y transition="scroll-y-transition" rounded="lg"> -->
    <!-- <template v-slot:activator="{ on, attrs }"> -->

    <div class="mt-5 d-flex align-center">
      <v-menu offset-y transition="scroll-y-transition" rounded="lg">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" elevation="" text small  class="mr-3">
            <v-icon left>mdi-menu-down-outline</v-icon>
            <p class="mr-5 mt-4  font-weight-bold primary--text" >{{ userInfo?.user?.email }}</p>
          </v-btn>
        </template>
        <v-list dense nav>
          <v-list-item @click="cerrarSession()" class="my-0">
            <v-list-item-icon>
              <v-icon size="20">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <div class="circular-container" v-show="breakpoint()">
        <v-icon size="50" color="#ffff" >mdi-account-outline</v-icon>
      </div>
      <!-- <v-avatar size="100">
        <v-img
          src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
        />
      </v-avatar> -->
    </div>
    <!-- </template> -->
    <!---->
    <!-- <v-list dense nav>
        <v-list-item to="#" class="my-0"> 
          <v-list-item-icon>
            <v-icon size="20">mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Perfil</v-list-item-title>
        </v-list-item>
        <v-list-item to="##" class="my-0"> 
          <v-list-item-icon>
            <v-icon size="20">mdi-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Seguridad</v-list-item-title>
        </v-list-item>
        <v-list-item @click="cerrarSession()" class="my-0">
          <v-list-item-icon>
            <v-icon size="20">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Cerrar Sesión</v-list-item-title>
        </v-list-item> -->
    <!-- <v-list-item class="my-0">
          <v-list-item-icon>
            <v-switch
                v-model="$vuetify.theme.dark"
                class="my-0"
                hint="This toggles the global state of the Vuetify theme"
                dense
            ></v-switch>
          </v-list-item-icon>
          <v-list-item-title>Tema Oscuro</v-list-item-title>
        </v-list-item> -->
    <!-- </v-list>
    </v-menu> -->
  </v-app-bar>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "AppHeader",
  methods: {
    ...mapMutations("utils", ["setSideBar", "setMenu"]),
    ...mapMutations(["setToken"]),
    async cerrarSession() {
      try {
        const token = localStorage.getItem("tokenIdentidad");

        await this.services.auth.logout({
          token_id: token
        });

        localStorage.clear();
        // Elimina todos los ítems de almacenamiento relacionados con la sesión
        localStorage.removeItem("token");
        localStorage.removeItem("tokenIdentidad");
        localStorage.removeItem("id_institucion");
        localStorage.removeItem("id_empleado_puesto");
        localStorage.removeItem("id_unidad_organizativa");

        // También asegúrate de limpiar el estado de autenticación en Vuex si es relevante
        await this.$store.dispatch('logout');

        // Finalmente, redirige al usuario a la página de login
        this.$router.push({ name: "login" }).catch((e) => {
          console.error('Error en la redirección:', e);
        });
      } catch (error) {
        console.error('Error al cerrar sesión:', error);
      }
    },

    breakpoint() {
      if (this.$vuetify.breakpoint.sm) {
        return false;
      } else if (this.$vuetify.breakpoint.xs) {
        return false;
      } else if (this.$vuetify.breakpoint.md) {
        return true;
      } else if (this.$vuetify.breakpoint.lg) {
        return true;
      } else if (this.$vuetify.breakpoint.xl) {
        return true;
      } else {
        return true;
      }
    },
  },
  computed: {
    ...mapState(["userInfo"]),
    ...mapState("utils", {
      sideBar: "sideBar",
    }),
  },
};
</script>

<style scoped>
.circular-container {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #1c1e4d;
  /* Fondo azul */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.circular-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
</style>
