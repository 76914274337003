import Vue from "vue";
import VueRouter from "vue-router";
import rolesRouter from "../views/roles/rolesRouter";
import profilesRouter from "../views/profiles/profilesRouter";
import usersRouter from "../views/users/userRouter";
import pathsRouter from "../views/paths/pathRouter";
import routerCargos from "../views/puestos/puestosRouter";
import routerFormacion from "../views/formacion/formacionRouter";
import capacidadesRouter from "../views/capacidades/capacidadesRouter";
import areasRouter from "../views/areas_interes/areasRouter";
import routerConocimientos from "../views/conocimientos/conocimientoRouter";
import routerPalabrasClaves from "../views/palabras_claves/palabrasClavesRouter";
import ofertasRouter from "../views/ofertas/routes/ofertasRouter";
import candidatosRouter from "../views/candidatos/candidatosRouter";
import routerEmpleo from "../views/card_puesto/cardRouter";
import preguntasFrecuentesRouter from "../views/preguntas_frecuentes/preguntasFrecuentesRouter";
import store from '../store/index.js'
import authServices from "../services/auth.services.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "layout" */ "../views/layout"),
    children: [
      {
        path: "/",
        name: "dashboard",
        component: () =>
          import(/* webpackChunkName: "profiles" */ "../views/dashboard"),
        meta: { requiresAuth: true }
      },
      {
        path: "profile",
        name: "profile",
        component: () =>
          import(/* webpackChunkName: "profiles" */ "../views/profile"),
        meta: { requiresAuth: true }
      },
      ...rolesRouter,
      ...profilesRouter,
      ...usersRouter,
      ...pathsRouter,
      ...routerCargos,
      ...routerFormacion,
      ...capacidadesRouter,
      ...areasRouter,
      ...routerConocimientos,
      ...routerPalabrasClaves,
      ...ofertasRouter,
      ...candidatosRouter,
      ...routerEmpleo,
      ...preguntasFrecuentesRouter,
      {
        path: "/security",
        name: "security",
        component: () =>
          import(/* webpackChunkName: "qr" */ "../views/auth/security"),
        meta: { requiresAuth: true }
      },
      {
        path: "/example",
        name: "example",
        component: () =>
          import(/* webpackChunkName: "example" */ "../views/example.vue"),
        meta: { requiresAuth: true }
      },
    ],
  },
  {
    path: '/seleccionar-puesto',
    name: 'seleccionar-puesto',
    component: () =>
        import(/* webpackChunkName: "seleccion-puesto" */ '../views/auth/card'),
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/login"),
    meta: { requiresAuth: false } // Asegúrate de que login no requiera autenticación
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () =>
      import(
        /* webpackChunkName: "recuperarPassword" */ "../views/auth/recoverPassword"
      ),
  },
  {
    path: "/reset-password/:id",
    name: "reset-password",
    component: () =>
      import(
        /* webpackChunkName: "resetPassword" */ "../views/auth/resetPassword"
      ),
  },
  {
    path: "/verify-mail/:token",
    name: "verifyMail",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/verifyMail"),
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    component: () =>
      import(/* webpackChunkName: "forbidden" */ "../views/forbidden"),
  },
  {
    path: "/autenticacionqr",
    name: "2fa",
    component: () => import(/* webpackChunkName: "qr" */ "../views/auth/2fa"),
  },
  {
    path: '*',
    redirect: '/forbidden'
  }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

// router.beforeEach(async (to, from, next) => {
//   if (to.fullPath === "/") {
//     const token = localStorage.getItem("token");
//     if (!token) {
//       next("/login");
//       return;
//     }
//   }
//   if (await Vue.prototype.canNext(to)) {
//     next();
//   } else {
//     next("/forbidden");
//   }
// });
router.beforeEach(async (to, from, next) => {

  const session = to.query.session || null; 
  let token = localStorage.getItem("token"); 

  try {

    if (session) {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.setItem("session_uuid", session);

      const queryWithoutSession = Object.assign({}, to.query);
      delete queryWithoutSession.session;

      if (!token) {
        try {
          const response = await authServices.obtenerSesionPorUUID(session);
          if (response.data?.token) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("refresh_token", response.data.refreshToken);
            token = response.data.token;
          }
        } catch (error) {
          console.error("Error al obtener la sesión:", error);
        }
      }

      next({ path: to.path, query: queryWithoutSession, replace: true });
      return;
    }

    if (to.path === '/login' && store.state.token) {
      return next('/');
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      const isValid = await store.dispatch('checkTokenValidity');
      if (isValid) {
        return next();
      } else {
        await store.dispatch('logout');
        return next('/login');
      }
    }

    return next();
  } catch (error) {
    await store.dispatch('logout');
    return next('/login');
  }
});



export default router;
