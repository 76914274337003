import Vue from "vue";
export async function handlePdfRequest(serviceFunction) {
    try {
      const response = await serviceFunction;
  
      if (response?.status === 200) {
        Vue.prototype.temporalAlert({
            show: true,
            message: "Acta generada correctamente",
            type: "success",
        });
        // this.temporalAlert({
        //     show: true,
        //     message: "Acta generada correctamente",
        //     type: "success",
        //   });
  
        mostrarPDF(response.data);
        return true;
      } else {
        Vue.prototype.temporalAlert({
            show: true,
            message: "Error desconocido",
            type: "warning",
        });
        // temporalAlert({
        //   show: true,
        //   message: "Error desconocido",
        //   type: "warning",
        // });
        return false;
      }
    } catch (error) {
         Vue.prototype.temporalAlert({
            show: true,
            message:  error.response.data.err.description,
            type: "warning",
        });
        // this.temporalAlert({
        //     show: true,
        //     message:  error.response.data.err.description,
        //     type: "warning",
        //   });
        console.log({error});
        
      return false;
    }
  }
  
  export function mostrarPDF(pdfData) {
    let base64Data, extension, mimeType;

    if (typeof pdfData === "object" && pdfData.archivo && pdfData.extension) {
        base64Data = pdfData.archivo;
        extension = pdfData.extension.toLowerCase();
    } else if (typeof pdfData === "string") {
        base64Data = pdfData;
        extension = base64Data.startsWith("JVBER") ? "pdf" : "png"; 
    } else {
       Vue.prototype.temporalAlert({
            show: true,
            message:  "Formato inválido",
            type: "warning",
        });
        return;
    }

    const mimeTypes = {
        pdf: "application/pdf",
        png: "image/png",
        jpg: "image/jpeg",
        jpeg: "image/jpeg",
        gif: "image/gif",
        svg: "image/svg+xml",
    };

    mimeType = mimeTypes[extension] || "application/octet-stream";
    const dataUrl = `data:${mimeType};base64,${base64Data}`;

    if (extension === "pdf") {
        const pdfViewer = document.createElement("object");
        pdfViewer.data = dataUrl;
        pdfViewer.type = "application/pdf";
        pdfViewer.style.width = "100%";
        pdfViewer.style.height = "100%";

        const pdfWindow = window.open("", "_blank");
        pdfWindow.document.title = "Vista de Archivo";
        pdfWindow.document.body.appendChild(pdfViewer);
    } else {
        const imgWindow = window.open("");
        imgWindow.document.write(`
              <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100vh;">
        <img src="${dataUrl}" style="max-width: 90%; max-height: 80vh; object-fit: contain;">
        <a href="${dataUrl}" download="archivo.${extension}" 
           style="margin-top: 15px; padding: 10px 15px; font-size: 16px; color: white; text-decoration: none; background-color: #1d1e4c; border-radius: 5px; display: flex; align-items: center; gap: 5px;">
            🖨 Descargar Imagen
        </a>
    </div>
        `);
        imgWindow.document.title = "Vista de Archivo";
    }
}

  

  