import {http_client} from "@/plugins/http_client";

const getOfertas = async (params = {}) => await http_client('/api/v1/ofertas', params)
const getPsicologoOfertas = async (params = {}) => await http_client('/api/v1/ofertas/psicologos', params)
const getActividades = async (id, params ={}) => await http_client(`/api/v1/detalle-oferta/${id}`, params, "get")
const getFormacion = async (id, params ={}) => await http_client(`/api/v1/detalle-oferta/formacion/${id}`, params, "get")
const getOtraFormacion = async (id, params ={}) => await http_client(`/api/v1/detalle-oferta/formacion-otras/${id}`, params, "get")
const getConocimiento = async (id, params ={}) => await http_client(`/api/v1/detalle-oferta/conocimiento/${id}`, params, "get")
const getOtroConocimiento = async (id, params ={}) => await http_client(`/api/v1/detalle-oferta/conocimiento-otras/${id}`, params, "get")
const getExperencia = async (id, params ={}) => await http_client(`/api/v1/detalle-oferta/experiencia-previa/${id}`, params, "get")
const getOtraExperencia = async (id, params ={}) => await http_client(`/api/v1/detalle-oferta/experiencia-previa-otras/${id}`, params, "get")
const getCapacidad = async (id, params ={}) => await http_client(`/api/v1/detalle-oferta/capacidad/${id}`, params, "get")
const getAspectos = async (id, params ={}) => await http_client(`/api/v1/detalle-oferta/aspecto/${id}`, params, "get")
const getOpcionExtra = async (id, params ={}) => await http_client(`/api/v1/detalle-oferta/opcion-extra/${id}`, params, "get")
const getOferta = async (id, params ={}) => await http_client(`/api/v1/ofertas/oferta/${id}`, params, "get")
const postPalabrasClave = async(id, body) => await http_client(`/api/v1/palabra-clave/${id}`,body,"POST")
const getPalabraClave = async(id,params = {}) => await http_client(`/api/v1/palabra-clave/${id}`,params,"get")
const deletePalabraClave = async(id) => await http_client(`/api/v1/palabra-clave/eliminar/${id}`,{}, "delete")
const updateOferta = async(body={} ) => await http_client(`/api/v1/ofertas/oferta/`,body, "put")
const updateOtraFormacion = async(id, body={} ) => await http_client(`/api/v1/detalle-oferta/formacion-indispensable/${id}`,body, "put")
const updateOtroConocimiento = async(id, body={} ) => await http_client(`/api/v1/detalle-oferta/conocimiento-indispensable/${id}`,body, "put")

// Detalle Candidato
const getFormacionesCandidatoOferta = async (id, params = {}) => await http_client(`/api/v1/aptitud-candidato/formacion/${id}`, params, "get")
const getFormacionesOferta = async(id, params = {}) => await http_client(`/api/v1/detalle-oferta/formacion/${id}`, params,"get")
const getConocimientosCandidatoOferta = async (id, params = {}) => await http_client(`/api/v1/aptitud-candidato/conocimiento/${id}`, params, "get")
const getConocimientosOferta = async(id, params = {}) => await http_client(`/api/v1/detalle-oferta/conocimiento/${id}`, params,"get")
const getHabilidadesCandidatoOferta = async (id, params = {}) => await http_client(`/api/v1/aptitud-candidato/habilidad/${id}`, params, "get")
const getHabilidadesOferta = async(id, params = {}) => await http_client(`/api/v1/detalle-oferta/capacidad/${id}`, params,"get")
const getEvaluacionesCandidatoOferta = async(params = {}) => await http_client(`/api/v1/aptitud-candidato/evaluacion`, params,"get")
const saveNota = async(body={} ) => await http_client(`/api/v1/oferta-persona-evaluacion/nota`,body, "post")
const getFile = async(id) => await http_client(`/api/v1/ofertas/descargar-archivo-declinacion/${id}`)
 //put para piscologo en evaluacion
 const updatePsicologo = async(idOferta, idPsicologo) => await http_client(`/api/v1/oferta-persona/evaluador/${idOferta}/${idPsicologo}`, {}, "put")
// Estado Postulación Candidato
const getEstadoCandidatoOferta = async(params = {}) => await http_client('/api/v1/ofertas/candidato-estado', params, "get")

// Candidatos a invitar
const getCandidatosInvitar = async (idOferta, params = {}) => await http_client(`/api/v1/invitation/list/${idOferta}`, params, "get")
const invitarCandidato = async (body) => await http_client('/api/v1/ofertas/invitacion', body, "POST")


//Generar pdf
const generarPdf = async(id, params = {}) => await http_client(`/api/v1/candidato-oferta/generar-acta/${id}`, params, "get")
const generarPdfHistorico = async(id, params = {}) => await http_client(`/api/v1/oferta-persona/historial/comprobante/${id}`, params, "get")
// Preseleccionar candidato
const preseleccionarCandidatoOferta = async(body = {}) => await http_client(`/api/v1/ofertas/candidato-preseleccionar`, body, "put")

//enviar registro de oferta 
const putOfertaId = async(id, params = {}) => await http_client(`/api/v1/ofertas/estado-proceso/${id}`, params, "put")
const RepublicarOfertaId = async(id, body ) => await http_client(`api/v1/ofertas/re-publicar/${id}`, body, "put")

//historial de oferta
const historialOferta = async(id) => await http_client(`/api/v1/ofertas/historial/${id}`, {}, "get")
export default {
    RepublicarOfertaId,
    deletePalabraClave,
    getActividades,
    getAspectos,
    getCandidatosInvitar,
    getCapacidad,
    getConocimiento,
    getOtroConocimiento,
    getConocimientosCandidatoOferta,
    getConocimientosOferta,
    getEstadoCandidatoOferta,
    getEvaluacionesCandidatoOferta,
    saveNota,
    getExperencia,
    getOtraExperencia,
    getFormacion,
    getOtraFormacion,
    getFormacionesCandidatoOferta,
    getFormacionesOferta,
    getHabilidadesCandidatoOferta,
    getHabilidadesOferta,
    getOferta,
    getOfertas,
    getOpcionExtra,
    getPalabraClave,
    invitarCandidato,
    postPalabrasClave,
    preseleccionarCandidatoOferta,
    updateOferta,
    generarPdf,
    updateOtraFormacion,
    updateOtroConocimiento,
    putOfertaId,
    getFile,
    getPsicologoOfertas,
    updatePsicologo,
    historialOferta,
    generarPdfHistorico,
}