<template>
  <v-dialog v-model="isVisible" max-width="600px" persistent>
    <v-card style="border-radius: 15px">
      <v-card-title class="d-flex justify-center align-center position-relative">
        <span class="text-h6 font-weight-bold mx-auto" style="color: #1d1e4c;">{{ title }}</span>
        <v-btn icon class="close-btn" @click="close" style="position: absolute; top: 0; right: 0;">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <slot name="content">
          <!-- 🔥 Contenido por defecto (Si no se pasa nada en el slot, se usa esto) -->
          <v-container style="max-height: 300px; overflow-y: auto;">
            <v-row justify="center">
              <v-col cols="12" v-for="(item, index) in items" :key="index">
                <v-card outlined class="pa-3 elevation-3">
                  <v-card-title class="d-flex justify-space-between align-center py-1">
                    <span class="text-body-2">Oferta: {{ item.estado_oferta }}</span>
                    <span class="text-caption font-weight-bold grey--text text--darken-2">{{ item.fecha_created }}</span>
                  </v-card-title>
                  <v-card-text class="py-0 mb-4">
                    <p class="text-caption ma-0 mb-4" v-if="item.justificacion">Justificación: {{ item.justificacion }}</p>
                    <h4 v-if="item.archivo" style="color: blue; cursor: pointer;" @click="verArchivo(item.id)">Ver archivo</h4>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </slot>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn style="background-color: #1d1e4c;" rounded dark @click="close">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

  
  
  <script>
  import { handlePdfRequest } from '../plugins/pdfHelpers';
  export default {
    name: "Historico",
    props: {
      visible: Boolean,
      title: String,
      items: Array // 🔹 Solo recibe los datos listos
    },
    data() {
      return {
        isVisible: this.visible
      };
    },
    watch: {
      visible(newValue) {
        this.isVisible = newValue;
      }
    },
    methods: {
      close() {
        this.$emit("update:visible", false);
      },

      async verArchivo(id){
        this.loading = true
        const success = await handlePdfRequest(this.services.ofertas.generarPdfHistorico(id))
        if (success) {
          this.loading = false
        }else{
          this.loading = false
        }
        
      }
    }
  };
  </script>
  