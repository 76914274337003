import {http_client} from "@/plugins/http_client";

const getCandidatoOfertas = async (id, params = {}) => await http_client(`/api/v1/candidato-oferta/candidato/${id}`, params)
const postGubernamental = async (body) => await http_client(`/api/v1/ofertas/concurso-gubernamental`, body, 'post')
const postInterno = async (body) => await http_client(`/api/v1/ofertas/concurso-interno`, body, 'post')
const postExterno = async (body) => await http_client(`/api/v1/ofertas/concurso-externo`, body, 'post')
const deleteCandidato = async (id) => await http_client(`/api/v1/candidato-oferta/candidato/eliminar/${id}`,{}, 'delete')
const getCandidatoPreseleccionado = async (id, params = {}) => await http_client(`/api/v1/candidato-oferta/preselecionado/${id}`, params)
const getContratados = async (id, params = {}) => await http_client(`/api/v1/candidato-oferta/contratacion/${id}`, params)
const getDescartados = async (id, params = {}) => await http_client(`/api/v1/candidato-oferta/declinado/${id}`, params)
const suspenderProceso = async (id, body) => await http_client(`/api/v1/ofertas/suspender-oferta/${id}`, body, 'put')
const getJustificacion = async (id, params = {}) => await http_client(`/api/v1/ofertas/oferta-justificacion/${id}`, params)



//candidato declinar:
const putDeclinarCandidato = async (body) => await http_client(`/api/v1/ofertas/candidato-declinar`, body, 'put')
const putContratarCandidato = async (body) => await http_client(`/api/v1/ofertas/candidato-en-contratacion`, body, 'put')
const putReactivarCandidato = async (body) => await http_client(`/api/v1/ofertas/candidato-reactivar`, body, 'put')

export default {
    getCandidatoOfertas,
    getCandidatoPreseleccionado,
    getContratados,
    postGubernamental,
    postInterno,
    postExterno,
    deleteCandidato,
    suspenderProceso,
    getJustificacion,
    putDeclinarCandidato,
    putContratarCandidato,
    getDescartados,
    putReactivarCandidato,
}